import "./ConferenceObjectives.css"; // Optional for styling
import Conference_image from "../../assets/imgs/Conference-image.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Conference_logo from "../../assets/imgs/Conference_logo.png";
import EnergyImage from "../../assets/imgs/Energy-image.png";

import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Countdown from "./Countdown";

const SustainableEngineering = () => {
  const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    dots: false,
    animateIn: "fadeIn",
    autoplayTimeout: 4000,
    smartSpeed: 450,
    autoplayHoverPause: true,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 1,
        dots: true,
      },
    },
  };
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [conferences, setConferences] = useState([]);
  const { name } = useParams();
  const result = conferences?.find((conference) => conference.name_en === name);
  console.log("result", result);

  useEffect(() => {
    fetch("https://mis.kfs-hiet.edu.eg/api/website/WebScientificConferences")
      .then((res) => {
        return res.json();
      })
      .then((data) => setConferences(data));
  }, []);
  const imgSrc = (photo) => {
    return (
      "https://mis.kfs-hiet.edu.eg/" + "public//storage/" + photo.replace("public", "")
    );
  };
  const getDegreeTitle = (selectedValue) => {
    switch (selectedValue) {
      case 1:
        return currentLang === "ar" ? " أستاذ" : "Professor";
      case 2:
        return currentLang === "ar" ? "مساعد استاذ" : "Assistant Professor";
      case 3:
        return currentLang === "ar" ? "محاضر" : "Lecturer";
      case 4:
        return currentLang === "ar" ? "مساعد محاضر" : "Teaching Assistant";
      case 5:
        return currentLang === "ar" ? "مذيع" : "Speaker";
      default:
        return "Please select a valid degree.";
    }
  };

  return (
    <>
      <div className="Conference_slider position-relative">
        <div className="container ">
          <div className="row  w-100 justify-content-between">
            <div className="col-12  col-md-6 d-flex flex-column overflow-hidden">
              <img
                src={Conference_logo}
                width={"150px"}
                className="mt-4"
                alt=""
              />
              <h2>{ currentLang === "ar" ? result?.name_ar:result?.name_en}</h2>
              <div
                className="des"
                dangerouslySetInnerHTML={{
                  __html:
                    currentLang === "ar" ? result?.notes_ar : result?.notes_en,
                }}
              ></div>
              {result?.active ? (
                <Link
                  to="/SustainableForm"
                  onClick={() => localStorage.setItem("savedId", result.id)}
                  class="btn btn-primary w-50"
                >
                  apply
                </Link>
              ) : null}
            </div>
            <div className="col-12  col-md-6 ">
              <OwlCarousel className={`owl_news owl-theme`} {...options}>
                {result?.photos.map((Element) => (
                  <div className={` item`} key={Element.id}>
                    <div>
                      {Element.photo ? (
                        <img
                          src={imgSrc(Element.photo)}
                          className="w-100 owl_image"
                          alt="owl_iamge"
                          title={"owl_iamge"}
                          loading="lazy"
                        />
                      ) : (
                        <img
                          className="Conference_slider-image"
                          src={Conference_image}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
        {result && <Countdown targetDate={result?.to} />}
      </div>
      <div className="conference-objectives">
        <div className=" container">
          <h2 className="mb-4">{t("Conference Objectives")}</h2>
          <div className="row justify-content-center">
            <div className="col-12 d-flex align-items-center conference-objectives-div  gap-4 bg-white mb-4 py-4 overflow-hidden">
              <span className="icon fs-2">
                <i class="fa-solid fa-newspaper"></i>
              </span>
              <span
                className="des"
                dangerouslySetInnerHTML={{
                  __html:
                    currentLang === "ar" ? result?.goals_ar : result?.goals_en,
                }}
              ></span>
            </div>
          </div>
          <div className="row EnergyImage">
            <div className="col-12 col-md-3 d-flex flex-column justify-content-between">
            <div className="d-flex gap-4 align-items-center">
            <i class="fa-solid fa-money-check-dollar"></i>
                <div className="d-flex flex-column">
                  <span>{t("price")}</span>
                  <span>{result?.price} {t("pound")}</span>
                </div>
              </div>
              <div className="d-flex gap-4 align-items-center">
                <i class="fa-regular fa-clock"></i>
                <div className="d-flex flex-column">
                  <span>{t("time")}</span>
                  <span>{result?.datetime}</span>
                </div>
              </div>
              <div className="d-flex gap-4 align-items-center">
                <i class="fa-regular fa-calendar"></i>
                <div className="d-flex flex-column">
                  <span>{t("date")}</span>
                  <span>
                    {t("from")} {result?.from}
                  </span>
                  <span>
                    {t("to")} {result?.to}
                  </span>
                </div>
              </div>
              <div className="d-flex gap-4 align-items-center">
                <i class="fa-solid fa-location-dot"></i>
                <div className="d-flex flex-column">
                  <span>{t("location")}</span>
                  <span>{result?.place}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-9">
              <img className="rounded-5" src={EnergyImage} alt="" />
              {/* <img className="rounded-5" src={imgSrc(result?.photos[Math.floor(Math.random() * result.photos.length)].photo)} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      <section class="conference">
        <div class="container">
                <p className="text-center fs-1 text-dark py-3">
                {t("Departments")}
                </p>
          <div className="row">
            {result?.scientific_conference_departments.map((item) => (
<>

              <div class="col-md-6 col-lg-6 conference_content  my-4 overflow-hidden">
              <div class="conference_item d-flex flex-column   px-3">
                  <div class="img_conference d-flex justify-content-center ">
                  <img className="rounded-4" src={imgSrc(item.photo)} alt="" />
                  </div>
                  <div class="conference_item2 d-flex flex-column gap-4 px-3  h-100  ">
                   <div>
                    <p class="hed fs-2 fw-bold">{currentLang === "ar"
                    ? item.departments.name_ar
                    : item.departments.name_en}</p>
                    <div className="overflow-hidden conference_item_pargrph"
                dangerouslySetInnerHTML={{
                  __html: currentLang === "ar" ? item.note_ar : item.note_en,
                }}
              />
                </div>
            </div>
              </div>
          </div>

        
           </> ))}
          </div>
        </div>
      </section>
      <section class="sustainable mt-4 mb-2">
        <div class="container">
          <div class="row">
            <div class="col-7 col-md-6 col-lg-4 py-3 ">
              <div class="d-flex gap-2 py-5">
                <div class="line"></div>
                <div class="px-2">
                  <p class="hed fs-1">
                  { currentLang === "ar" ? result?.name_ar:result?.name_en}
                  </p>
                  {/* {result?.active ? <Link to="/SustainableForm" onClick={()=> localStorage.setItem('savedId', result.id)} class="btn btn-primary">apply</Link>:null}  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container mt-5">
        <div class="section-title">
          <h2 class="compaines">{t("Companies participatin")}</h2>
        </div>
        <div class="row text-center">
          {result?.scientific_conference_companys.map((item) => (
            <div class="col-md-3 mb-4 image-company">
              <div class="company-logo"></div>
              <img class="ellipse"  src={imgSrc(item.photo)} alt="Company 1" />
              <p className="fs-5">
                {currentLang === "ar" ? item.name_ar : item.name_en}
              </p>
              <p className="fs-4">
                {currentLang === "ar" ? item.company_ar : item.company_en}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div class="container mt-5">
        <div class="section-title">
          <h2 class="compaines">{t("Our Keynote Speakers of company")}</h2>
        </div>
        <div class="row justify-content-center gap-4">
          {result?.arbitrators?.map((arbitrator) => (
            <div class="col-md-3">
              <div class="speaker-card d-flex flex-column justify-content-center">
                {arbitrator.photo && (
                  <img
                    src={imgSrc(arbitrator.photo)}
                    alt={arbitrator.name_ar}
                    title={arbitrator.name_ar}
                  />
                )}
                <h5>
                  {currentLang === "ar"
                    ? arbitrator.name_ar
                    : arbitrator.name_en}
                </h5>
                <p className="fs-4">{getDegreeTitle(arbitrator.kind)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SustainableEngineering;
